import { Auth, API, graphqlOperation } from "aws-amplify";
import AWS from "aws-sdk";
import * as queries from "../graphql/queries";

/**
 * Attaches IoT policy to a newly registered user.
 */
async function attachIoTPolicy() {
    const policyName = "myIoTPolicy";  //TODO: Centralization config
    const credentials = await Auth.currentCredentials();
    const target = credentials.identityId;
    const iot = new AWS.Iot({
        region: "eu-central-1",  //TODO: Centralization config
        credentials: Auth.essentialCredentials(credentials),
    });
    const { policies } = await iot.listAttachedPolicies({ target }).promise();
    if (!policies.find((policy) => policy.policyName === policyName)) {
        await iot.attachPolicy({ policyName, target }).promise();
    }
}

/**
 * Gets current username
 * @returns current username
 */
async function getCurrentUserName() {
    const currentUserInfo = await Auth.currentUserInfo();
    return currentUserInfo["username"];
}

/**
 * Get all device ARNs (thing names) that belong to the current user from the backend DB as an array.
 * @returns Array of ARNs
 */
async function getAllUserDeviceARNs() {
    let userDeviceARNArray = [];
    let currentUsername = await getCurrentUserName()
    const userDevicesTableItems = await API.graphql(
        graphqlOperation(queries.listUserDevices, {
            filter: { UserName: { eq: currentUsername } },
        })
    );

    try {
        let items = userDevicesTableItems.data["listUserDevices"]["items"];
        for (let ind in Object.keys(items)) {
            userDeviceARNArray.push(items[ind]["NanoARN"]);
        }
    } catch (error) {
        console.log(error);
    }
    return userDeviceARNArray;
}


export { attachIoTPolicy, getCurrentUserName, getAllUserDeviceARNs}