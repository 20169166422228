<script setup>
import DeviceStatusVue from "@/components/DeviceStatus.vue";
import DeviceRegisterVue from "@/components/DeviceRegister.vue";
import RemoteStorageExplorer from "@/components/RemoteStorageExplorer.vue";
import AudioRecorder from "@/components/AudioRecorder.vue";
</script>
<script>
import "@aws-amplify/ui-vue/styles.css";
import { getAllUserDeviceARNs } from "@/sountiolib/user-related-operations";

export default {
  data() {
    return {
      userDeviceARNArray: [], // Array of thing names that belong to the current user
    };
  },
  methods: {
    /**
     * Puts all device ARNs (thing names) that belongs to the current user into an array:
     *  this.userDeviceARNArray
     * Each item in the ARN array will be consumed by DeviceStatusVue to show the corresponding device info.
     */
    async setUserDeviceARNArrayFromDeviceDB() {
      this.userDeviceARNArray = await getAllUserDeviceARNs();
    },
  },

  mounted() {
    this.setUserDeviceARNArrayFromDeviceDB();
  },
};
</script>

<template>
  <div class="row">
    <div class="col-10 d-flex justify-content-center">
      <!-- List cards of device status from the component DeviceStatusVue -->
      <div v-for="userDeviceARN in userDeviceARNArray" :key="userDeviceARN">
        <DeviceStatusVue :thingName="userDeviceARN" />
      </div>
      <br />
    </div>
  </div>
  <div class="row">
    <div class="col-3" align="center">
      <!-- Add device based on the nanoID given by the user  -->
      <DeviceRegisterVue
        @changeOfDeviceOwnerEvent="setUserDeviceARNArrayFromDeviceDB"
      />
    </div>

    <div class="col-3 justify-content-center" align="center">
      <!-- Recording. -->
      <!-- Show recordings that belong to the current user -->
      <AudioRecorder :userDeviceARNArray="userDeviceARNArray" />
    </div>

    <div class="col-6 justify-content-center" align="center">
      <!-- Show recordings that belong to the current user -->
      <RemoteStorageExplorer :userDeviceARNArray="userDeviceARNArray" />
    </div>
  </div>
</template>
