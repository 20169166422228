<!-- 
  Register new device for the current user, if the user knows the nano id. 
-->

<template>
  <div class="card" align="left" style="width: 20rem">
    <div class="card-body">
      <h4 class="card-title">Add device</h4>
      <p>
        Nano ID:
        <input v-model="nanoId" placeholder="ID on the box" />
      </p>
      <button
        class="btn btn-primary mb-3"
        v-on:click="addCurrentUserAsNanoIdOwner"
      >
        Add device
      </button>
    </div>
  </div>
</template>


<script>
import { updateUserDevice } from "../graphql/mutations";
import {
  attachIoTPolicy,
  getCurrentUserName,
} from "@/sountiolib/user-related-operations";
import { API, graphqlOperation } from "aws-amplify";
export default {
  data() {
    return {
      nanoId: "",
    };
  },

  methods: {
    /**
     * Adds current user's name to the device with this.nano_id in UserDevice table,
     * so the current use is treated as the owner of the device.
     */
    async addCurrentUserAsNanoIdOwner() {
      let currentUsername = await getCurrentUserName();
      try {
        await API.graphql(
          graphqlOperation(updateUserDevice, {
            input: {
              id: this.nanoId,
              UserName: currentUsername,
            },
          })
        );
        attachIoTPolicy();
        this.notifyParentAboutChangeOfDeviceOwner();
      } catch (error) {
        console.log(error);
      }
    },

    /**
     * Notify the parent about the change of the device owner.
     * The parent should listen to the eventName.
     */
    notifyParentAboutChangeOfDeviceOwner() {
      let eventName = "changeOfDeviceOwnerEvent";
      this.$emit(eventName);
    },
  },

  mounted() {},
};
</script>