<!-- 
  Update system info on the webpage. The update frequency is decided by deviceInfoUpdateIntervalMilliSec
-->

<template>
  <div class="card shadow mb-4" align="left" style="width: 20rem">
    <div class="card-body">
      <h4 class="card-title">{{ thingName }}</h4>
      <p class="fw-bold" :class="getTextColorBasedOnOfflineStatus()">
        {{ status }}
      </p>
      <p>CPU Usage: {{ cpuUse }}</p>
      <p>RAM Usage: {{ ramUse }}</p>
      <p>Disk Usage: {{ diskUse }}</p>
    </div>
  </div>
</template>


<script>
import { PubSub } from "aws-amplify";
import {
  getTopicNameSubscribedByThing,
  getSystemStatusTopicNameSubscribedByBackend,
} from "../sountiolib/pubsub-topic-definition";

export default {
  props: {
    thingName: String,
  },

  data() {
    return {
      cpuUse: "--",
      ramUse: "--",
      diskUse: "--",
      status: "Offline",
      deviceInfoUpdateIntervalMilliSec: 60000,
    };
  },

  methods: {
    /**
     * Updates system info on the webpage.
     * @param {*} responseJSON
     */
    updateSystemInfoBasedOnDeviceResponse(responseJSON) {
      if (responseJSON["cpuUse%"] != undefined) {
        // When it's recording, some sys info are undefined. So update them only when they are defined.
        this.cpuUse = responseJSON["cpuUse%"] + "%";
        this.ramUse = responseJSON["ramUse%"] + "%";
        this.diskUse = responseJSON["diskUse%"] + "%";
      }
      this.status = responseJSON["recStatus"];
      console.log(this.thingName + "'s system info updated!");
    },

    /**
     * Starts the while loop to update the system info.
     * The update frequency is based on "deviceInfoUpdateIntervalMilliSec"
     */
    async startDeviceStatusUpdatingLoop() {
      // First, we need to know the topic subscribed by the things:
      const requestTopic = getTopicNameSubscribedByThing(this.thingName);
      // Second, we need to know the topic name that this backend will subscribe,
      // to which the things will send the response back
      const respTopic = getSystemStatusTopicNameSubscribedByBackend(
        this.thingName
      );
      let pubMsg = { rtn: respTopic, rf: "get_system_info", rfi: {} };
      // Subscribe to the topic. Enough to do it only once.
      PubSub.subscribe(respTopic).subscribe({
        next: (data) =>
          this.updateSystemInfoBasedOnDeviceResponse(data["value"]),
        error: (error) => console.error(error),
        complete: () => console.log("One call to get_system_info done."),
      });
      let i = 0; // To avoid "no-constant-condition", we need to use while i<1 instead of while (true)
      while (i < 1) {
        PubSub.publish(requestTopic, pubMsg);
        await new Promise((resolve) =>
          setTimeout(resolve, this.deviceInfoUpdateIntervalMilliSec)
        );
      }
    },

    /**
     * Determine text color based on thing's offline status.
     * Return a string that represents the color.
     */
    getTextColorBasedOnOfflineStatus() {
      if (this.status === "Offline") {
        return "text-danger";
      } else {
        return "text-success";
      }
    },
  },

  mounted() {
    this.startDeviceStatusUpdatingLoop();
  },
};
</script>