<!-- Show recordings that belong to the current user -->


<template>
  <div class="card" align="left" style="width: 50rem">
    <div class="card-body">
      <h4 class="card-title">Remote storage explorer</h4>
      <Vue3EasyDataTable :headers="headers" :items="items">
        <template #item-operation="item">
          <div class="operation-wrapper">
            <img
              src="@/assets/img/delete.png"
              class="operation-icon"
              @click="deleteItem(item)"
            />
            <img
              src="@/assets/img/download.png"
              class="operation-icon"
              @click="downloadItem(item)"
            />
          </div>
        </template>
      </Vue3EasyDataTable>
      <div class="row">
        <div class="col-4" align="center">
          Select thing name:
          <select
            ref="Select-thing-name"
            class="form-select"
            aria-label="Default select example"
          >
            <option
              v-for="userDeviceARN in userDeviceARNArray"
              :key="userDeviceARN"
              :value="userDeviceARN"
            >
              {{ userDeviceARN }}
            </option>
          </select>
        </div>
        <div class="col-4" align="center">
          <button class="btn btn-primary mb-3" v-on:click="updateTable">
            Update table
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
</script>

<script>
import { Storage } from "aws-amplify";
import {
  getTableDataFromObjectFilePaths,
  recoverS3FilePathFromTableDataItem,
  downloadBlob,
} from "@/sountiolib/s3-files-processor";
export default {
  props: {
    userDeviceARNArray: Array,
  },
  data() {
    return {
      headers: [
        { text: "File Name", value: "recordingFileName", sortable: true },
        { text: "Recording Dates", value: "recordingDate", sortable: true },
        { text: "Thing Name", value: "thingName", sortable: true },
        { text: "Operation", value: "operation" },
      ],
      items: [],
    };
  },

  methods: {
    updateTable() {
      let thingName = this.$refs["Select-thing-name"].value;
      Storage.list(thingName, { level: "public" }) // for listing ALL files without prefix, pass '' instead
        .then(
          (result) => (this.items = getTableDataFromObjectFilePaths(result))
        )
        .catch((err) => console.log(err));
    },
    /**
     * Delete an object from both the table and the remote storage
     * @param {} selectedItem
     */
    async deleteItem(selectedItem) {
      let s3FilePath = recoverS3FilePathFromTableDataItem(selectedItem);
      await Storage.remove(s3FilePath);
      this.items = this.items.filter(
        (item) => item.recordingFileName !== selectedItem.recordingFileName
      );
      console.log(s3FilePath + " removed!");
    },
    /**
     * Download a object to local computer
     * @param {*} selectedItem
     */
    async downloadItem(selectedItem) {
      let s3FilePath = recoverS3FilePathFromTableDataItem(selectedItem);
      const result = await Storage.get(s3FilePath, { download: true });
      downloadBlob(result.Body, selectedItem.recordingFileName);
    },
  },

  mounted() {},
};
</script>

<style>
.operation-wrapper .operation-icon {
  width: 20px;
  cursor: pointer;
}
</style>