<script setup>
// This starter template is using Vue 3 <script setup> SFCs
// Check out https://v3.vuejs.org/api/sfc-script-setup.html#sfc-script-setup
import { RouterView } from "vue-router";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-vue";
import "@aws-amplify/ui-vue/styles.css";
const auth = useAuthenticator();
</script>

<template>
  <nav class="navbar">
    <div class="container-fluid">
      <div class="navbar-header"></div>
      <ul class="nav navbar-nav navbar-right">
        <template v-if="auth.route === 'authenticated'">
          <button type="button" class="btn btn-primary" @click="auth.signOut">
            Sign out
          </button>
        </template>
      </ul>
    </div>
  </nav>

  <authenticator class="d-flex justify-content-center">
    <amplify-sign-in :hide-sign-up="true" username-alias="email">
    </amplify-sign-in>
  </authenticator>

  <div v-if="auth.route === 'authenticated'">
    <RouterView />
  </div>
</template>

<style>
.amplify-tabs {
  display: none;
}
</style>