/**
 * Parse object file path
 * @param {String} path Storage object file path, in the form of {thingName}/YYYY/MM/DD/{recordingFileName}
 * @returns { recordingFileName: {recordingFileName}, recordingDate: YYYY/MM/DD, thingName: {thingName} }
 */
function _parseObjectFilePath(path) {
    let tableDataItem = {
        recordingFileName: null,
        recordingDate: null,
        thingName: null
    }
    let possibleFolder = path.split('/')
    if (possibleFolder) {
        tableDataItem.thingName = possibleFolder[0]
        tableDataItem.recordingFileName = possibleFolder[possibleFolder.length - 1]
        tableDataItem.recordingDate = possibleFolder.slice(1, -1).join('/')
    }
    return tableDataItem;
}


/**
 * Recovers a TableDataItem back to s3 file path
 */
function recoverS3FilePathFromTableDataItem(tableDataItem) {
    let filePath = tableDataItem.thingName + "/" + tableDataItem.recordingDate + "/" + tableDataItem.recordingFileName
    return filePath
}

/**
 * Download a blob. 
 * Storage.get returns a signed URL string to your file.
 * If download is true, Storage.get returns an object with a Body field of type Blob. 
 * It is up to you to programmatically save it to disk (we suggest a method below) 
 * or do whatever else you need with it.
 * See https://docs.amplify.aws/lib/storage/download/q/platform/js/
 * @param {*} blob 
 * @param {*} filename 
 * @returns 
 */
function downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
  }


/**
 * Parse the returned file names into table data, where the table header is defined as 
 * headers: [
 *       { text: "File Name", value: "recordingFileNames" },
 *       { text: "Recording Dates", value: "recordingDates", sortable: true },
 *       { text: "Thing Name", value: "thingNames", sortable: true }
 *     ],
 * 
 * Now the path structure of an object is 
 *  {thingNames}/YYYY/MM/DD/{recordingFileNames}
 * @param {*} result Returned result via Storage.list
 * @return tableData
 */
function getTableDataFromObjectFilePaths(result) {
    let tableData = [];
    result.forEach((res) => {
        if (res.size) {
            tableData.push(_parseObjectFilePath(res.key))
        }
    });
    return tableData;
}





export { getTableDataFromObjectFilePaths, recoverS3FilePathFromTableDataItem, downloadBlob}