import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
// aws-amplify
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
Amplify.configure(awsExports);
import "@aws-amplify/ui-vue/styles.css";


// aws-ui-vue
import AmplifyVue from "@aws-amplify/ui-vue";
// bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
// IOT core
import { AWSIoTProvider } from '@aws-amplify/pubsub';
Amplify.addPluggable(new AWSIoTProvider({
    aws_pubsub_region: 'eu-central-1',   //TODO: Centralization config
    aws_pubsub_endpoint: 'wss://a27vzdhcj3ghkv-ats.iot.eu-central-1.amazonaws.com/mqtt',
}));


const app = createApp(App);
app.use(AmplifyVue);
app.use(router);

app.mount("#app");