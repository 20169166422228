const PROJECT_NAME = "poc"; // TODO: Read PROJECT_NAME from a config file
const THING_GROUP_NAME = "arec_nano"; // TODO: Read THING_GROUP_NAME from a config file

/**
 * Gets the topic subscribed by the things.
 * The definition of the topic should be synced with the GreenGrass component. 
 * See https://github.com/wengchenyang1/IoTCorePubSub for details about the topic name definition.
 * @param {*} thingName 
 * @returns 
 */
function getTopicNameSubscribedByThing(thingName) {
    let topic = PROJECT_NAME + "/" + THING_GROUP_NAME + "/" + thingName
    return topic
}

/**
 * Defines the topic name that this backend will subscribe, to which the things will 
 * send the response back.
 * Unlike the topic subscribed by things, which should be predefined on the GreenGrass components, the 
 * topic subscribed by the backend can be independently defined on the backend side and will be sent to 
 * the components via MQTT. 
 * @param {*} thingName 
 * @returns 
 */
function getSystemStatusTopicNameSubscribedByBackend(thingName) {
    let responseTopic = "RES2BE/" + getTopicNameSubscribedByThing(thingName) + "/STATUS";
    return responseTopic
}


export { getTopicNameSubscribedByThing, getSystemStatusTopicNameSubscribedByBackend }