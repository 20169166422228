
<template>
  <div class="card" align="left" style="width: 20rem">
    <div class="card-body">
      <h4 class="card-title">Recording</h4>
      <p>
        Select thing name: <br />
        <select
          ref="Select-thing-name"
          class="form-select"
          aria-label="Default select example"
        >
          <option
            v-for="userDeviceARN in userDeviceARNArray"
            :key="userDeviceARN"
            :value="userDeviceARN"
          >
            {{ userDeviceARN }}
          </option>
        </select>
      </p>
      <p>
        Duration (sec): <br />
        <input v-model="duration" />
      </p>
      <p>
        File name prefix: <br />
        <input v-model="fileNamePrefix" />
      </p>
      <p>
        Tag: <br />
        <input v-model="tag" />
      </p>
      <p>
        Upload to remote storage: <br />
        <select
          ref="Upload"
          class="form-select"
          aria-label="Default select example"
        >
          <option key="True" value="True">Yes</option>
          <option key="False" value="False">No</option>
        </select>
      </p>
      <div class="btn btn-primary me-5" v-on:click="startRecording()">
        Start
      </div>

      <div class="btn btn-primary" v-on:click="stopRecording()">Stop</div>
    </div>
  </div>
</template>


<script>
import { PubSub } from "aws-amplify";
import {
  getTopicNameSubscribedByThing,
  getSystemStatusTopicNameSubscribedByBackend,
} from "../sountiolib/pubsub-topic-definition";
export default {
  props: {
    userDeviceARNArray: Array,
  },
  data() {
    return {
      duration: 5,
      fileNamePrefix: "rec",
      tag: "street",
    };
  },
  methods: {
    /**
     * Start recording by publishing a mqtt msg to the thing chosen by the user.
     */
    startRecording() {
      let thingName = this.$refs["Select-thing-name"].value;
      let upload = this.$refs["Upload"].value;
      let funcInput = {
        duration_sec: this.duration,
        base_filename: this.fileNamePrefix,
        tag: this.tag,
        upload: upload,
      };
      const requestTopic = getTopicNameSubscribedByThing(thingName);
      const respTopic = getSystemStatusTopicNameSubscribedByBackend(thingName);
      let pubMsg = {
        rtn: respTopic,
        rf: "start_recording_audio",
        rfi: funcInput,
      };
      PubSub.publish(requestTopic, pubMsg);
    },
    /**
     * Stop recording by publishing a mqtt msg to the thing chosen by the user.
     */
    stopRecording() {
      let thingName = this.$refs["Select-thing-name"].value;
      const requestTopic = getTopicNameSubscribedByThing(thingName);
      const respTopic = getSystemStatusTopicNameSubscribedByBackend(thingName);
      let pubMsg = { rtn: respTopic, rf: "stop_recording_audio", rfi: {} };
      PubSub.publish(requestTopic, pubMsg);
    },
  },

  mounted() {},
};
</script>